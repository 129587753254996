/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.noticeList {
  min-width: 400px;
  max-height: 550px;
  overflow-y: auto;
}
.noticeItem {
  overflow: hidden;
  cursor: pointer;
  word-break: break-all;
  transition: all 0.33s;
}
.noticeItem:hover {
  background: #e6f7ff;
}
.noticeItem .read {
  opacity: 0.7;
}
.message {
  display: flex;
  align-items: center;
}
.description {
  padding-left: 10px;
}
.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unread {
  background-color: #87d068;
}
